import React from 'react';
import s from './stylesFrame';
import { Buffer } from 'buffer';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { LoginUser, ReqChangePasswd } from '../../store/user/actions';
import queryString from 'query-string';
import { DefaultInput } from '../ui';
import GoogleAuthButton from './components/GoogleAuthButton';
import FaceBookAuthButton from './components/FaceBookAuthButton';
import FaceBookAuthButtonFB from './components/FaceBookAuthButtonFB';

import './styles.css';
import { URI } from '../../config';
import FaceBookAuthIphoneFB from "./components/FaceBookAuthIphoneFB";

export const LoginFrame: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isPasswd, setIsPasswd] = React.useState(0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState({
    status: '',
    url: '',
    tokenCoock: '',
  });
  const [redirect, setRedirect] = React.useState('');
  const [appId, setAppId] = React.useState('');
  const [texts, setTexts] = React.useState({
    title: '',
    textHtml: '',
    textBtn: '',
    textUnderBtn: '',
    backgroundColor: '',
    isBtnClose: true,
    typePaywall: ''
  });
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const dispatch = useDispatch();
  const params = useLocation();
  React.useEffect(() => {
    if (success && success.status) {
      window.parent.postMessage(JSON.stringify(success), '*');
      setSuccess({
        status: '',
        url: '',
        tokenCoock: '',
      });
    }
    if (error) {
      window.parent.postMessage(JSON.stringify({ message: error }), '*');
      setSuccess({
        status: '',
        url: '', 
        tokenCoock: '',
      });
    }
  }, [success, error]);

  React.useEffect(() => {
    if (params?.search) {
      const obj = queryString.parse(params?.search.substring(1));
      for (const key in obj) {
        if (key === 'redirectUrl') setRedirect(obj[key] as string);
        if (key === 'app') setAppId(obj[key] as string);
        if (key === 'email') setEmail(obj[key] as string);
        if (key === 'data') {
          try {
            const buff = Buffer.from(obj[key] as string, 'base64');
            const data = JSON.parse(decodeURIComponent(buff.toString()));
            const apiData = {
              title: typeof data.title === 'string' ? data.title : '',
              textHtml: typeof data.textHtml === 'string' ? data.textHtml : '',
              textBtn: typeof data.textBtn === 'string' ? data.textBtn : '',
              textUnderBtn: typeof data.textUnderBtn === 'string' ? data.textUnderBtn : '',
              backgroundColor: typeof data.backgroundColor === 'string' ? data.backgroundColor : '',
              isBtnClose: !!data.isBtnClose,
              typePaywall: data?.typePaywall || ''
            }
            setTexts(apiData);
          } catch (err) {
            console.error(err);
          }
        }
      }
    }
  }, [params.search]);



  const handleClick = () => {
    if (!email) return setError('Поле пошта обов’язкове');
    if (!password) return setError('Поле пароль обов’язкове');
    setLoading(true);
    dispatch(
      LoginUser.request({
        data: {
          email,
          password,
        },
        callBack: (succ, data) => {
          setLoading(false);
          if (!succ && data.message) {
            setError(data.message);
          }
          if (succ) {
            setSuccess({
              status: 'success',
              url: '',
              tokenCoock: data?.tokenCoock || ``

            })
          }
        },
      })
    );
  };

  const handleRemember = () => {
    if (!email) return setError('Поле пошта обов’язкове');
    setLoading(true);
    dispatch(
      ReqChangePasswd.request({
        data: {
          email,
        },
        callBack: (success, data) => {
          setLoading(false);
          if (!success && data.message) {
            setError(data.message);
          } else {
            setIsPasswd(1);
          }
        },
      })
    );
  };
  const isFacebookAndorid = navigator.userAgent.indexOf("FBAV") !== -1
  const isFacebookIphone = navigator.userAgent.indexOf("FBAN") !== -1

  return (
    <div
      style={{ height: '100%' }}
      data-analytic={texts.typePaywall}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <s.WrapperS>
        <div style={{
          color: '#000',
          font: '14px/18px sans-serif',
          backgroundClip: 'padding-box',
          backgroundColor: texts.backgroundColor || '#fff',
          borderColor: '#e5e5e5 #cccccc #b4b4b4',
          borderRadius: '4px',
          borderStyle: 'solid',
          borderWidth: '1px',
          boxShadow: '0 4px 15px rgb(74 70 62 / 35%)',
          overflow: 'hidden',
          textAlign: 'left',
          width: '660px',
          margin: '40px auto 0 auto',
          maxWidth: '100%',
        }}>
          <s.MainS>
            {texts.title && (
              <s.TitleS>{texts.title}</s.TitleS>
            )}
            {texts.textHtml && (
              <s.TitleSub>
                <div
                  dangerouslySetInnerHTML={{ __html: texts.textHtml }}>
                </div>
              </s.TitleSub>
            )}
            <s.WrapperCascadeS>
              <s.Cascade1S>
                <DefaultInput
                  styleWrap={{ padding: 0 }}
                  type="email"
                  placeholder="Ел. пошта"
                  handleChange={(value) => {
                    setError('');
                    setEmail(value);
                  }}
                  value={email}
                />
                <DefaultInput
                  styleGroup={{ margin: 0 }}
                  styleWrap={{ padding: 0 }}
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Пароль"
                  handleChange={(value) => {
                    setError('');
                    setPassword(value);
                  }}
                  value={password}
                  error={error}
                />
                <s.FieldEye onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  {isPasswordVisible ? (
                    <img src="https://dev.id3.expertus.com.ua/media/images/eye_passw.png" alt="" />

                  ) : (
                    <img src="https://dev.id3.expertus.com.ua/media/images/hidden.png" alt="" />

                  )}
                </s.FieldEye>
                {!isPasswd ? <s.RememberS onClick={handleRemember}>нагадати</s.RememberS> : null}
                {isPasswd === 1 ? (
                  <s.EneredS onClick={() => setIsPasswd(0)}>
                    <div>Лист відправлено, перевірте пошту</div>
                    <s.EneredBtnS>ввести</s.EneredBtnS>
                  </s.EneredS>
                ) : null}
              </s.Cascade1S>
              <s.Cascade2S />
              <s.Cascade3S>
                {texts.textBtn && (
                  <Link
                    style={{
                      display: 'block',
                      width: '100%',
                      padding: '7px',
                      color: 'black',
                      borderRadius: '3px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      border: 'none',
                      backgroundColor: 'rgb(255 186 0)',
                      backgroundImage: 'linear-gradient(rgb(524, 207, 0) 5%, rgb(240 175 0) 80%)'
                    }}
                    to="/registration"
                    onClick={(e) => {

                      if (redirect) {
                        e.preventDefault();
                        const link = document.createElement('a');
                        document.body.appendChild(link);
                        link.href = `${URI.trim()}/api/auth/site/registration?app=${appId}&redirectUrl=${redirect}`;
                        link.target = '_blank';
                        window.open(`${URI.trim()}/api/auth/site/registration?app=${appId}&redirectUrl=${redirect}`)
                        document.body.removeChild(link);

                      }
                    }}
                  >
                    {texts.textBtn}
                  </Link>
                )}
                {texts.textUnderBtn && (
                  <s.UnderBtn>
                    {texts.textUnderBtn}
                  </s.UnderBtn>
                )}
              </s.Cascade3S>
            </s.WrapperCascadeS>
            <div style={{ overflow: 'hidden', display: 'flex', width: '340px', justifyContent: 'space-between' }}>
              <s.ButtonS disabled={loading || !!error} onClick={handleClick}>
                Увійти
              </s.ButtonS>
              <s.btnWrapSoc>
                    {isFacebookAndorid || isFacebookIphone ? (
                      <FaceBookAuthIphoneFB appId={appId} setError={setError} isLogin={true} redirect={encodeURIComponent(redirect)} setIsSucc={setSuccess} />
                    ) : (
                      <>
                        <GoogleAuthButton
                          setIsSucc={setSuccess}
                          appId={appId}
                          setError={setError}
                          isLogin={true}
                          redirect={redirect}

                        />
                        <FaceBookAuthButton
                          setIsSucc={setSuccess}
                          appId={appId}
                          setError={setError}
                          isLogin={true}
                          redirect={redirect} />
                      </>
                    )}


              </s.btnWrapSoc>
            </div>
            <s.Cascade2S />
          </s.MainS>
          <s.FooterS>
            <s.WrapperLogoS />© Цифрове видавництво Експертус
          </s.FooterS>
        </div>
      </s.WrapperS>
    </div>
  );
};
