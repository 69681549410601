import React, { useEffect } from "react";

export const AutoGoogleAuth0: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCredentialResponse = (response: any) => {
    const { credential } = response;
    if (credential) {
      // send credential by postmessage to parent
      window.parent.postMessage({ credential }, "*");
    }
  };

  useEffect(() => {
    (window as any).handleCredentialResponse = handleCredentialResponse;
  }, []);

  useEffect(() => {
    //get goole_id from params
    const urlParams = new URLSearchParams(window.location.search);
    const google_id = urlParams.get("google_id") || "";
    const div = document.createElement("div");
    div.id = "g_id_onload";
    div.dataset.client_id = "1051577674611-1v5btgm5ag7rvlmem9okq0ef61ruqblg.apps.googleusercontent.com";
    div.dataset.callback = "handleCredentialResponse";
    div.dataset.auto_select = "true";
    div.dataset.use_fedcm_for_prompt = "false";
    div.dataset.login_hint = google_id;
    div.style.position = "fixed";
    div.style.top = "100px";
    div.style.right = "100px";
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    }
  }, []);

  return <></>;
};