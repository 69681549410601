// @ts-nocheck
import React, { useEffect, useState } from 'react';
import '../styles';
import {GetFBUser} from "../../../store/user/actions";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const FacebookAuthComponent = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isInFacebookBrowser, setIsInFacebookBrowser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = (data) => {
    if (data && data.facebookUserId) {
      dispatch(
        GetFBUser.request({
          data: data,
          callBack: (succ: any, data: any) => {
            alert(JSON.stringify(data));
            if (props.setIsSucc) {
              if (succ) {
                props.setIsSucc({
                  status: 'success',
                  url: '',
                  tokenCoock: data?.tokenCoock || `${JSON.stringify(data)} NO tokenCoock2`
                })
              } else {
                const getDataParam = `&email=${data?.email ? data.email : ''}&firstName=${data?.firstName ? data?.firstName : ''}&lastName=${data?.lastName ? data?.lastName : ''}`
                if (data.redirect) {
                  props.setIsSucc({
                    status: 'register',
                    url: `${data.redirect}${getDataParam}`,

                  })
                }

              }
            } else {
              if (!succ && data.message) {
                props.setError(data.message);
              }
              if (!succ && data.redirect) {
                if (props.redirect) {
                  navigate(data.redirect);
                } else {
                  navigate(`/registration`);
                }
              }
              if (succ) {
                navigate('/main');
              }
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    // Визначаємо чи ми у Facebook браузері
    const checkFacebookBrowser = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return userAgent.indexOf("FBAN") > -1 || userAgent.indexOf("FBAV") > -1;
    };

    setIsInFacebookBrowser(checkFacebookBrowser());

    // Ініціалізація Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true, // Важливо для перевірки попередньої авторизації
        xfbml: true,
        version: 'v19.0'
      });

      setIsSdkLoaded(true);

      // Перевіряємо статус одразу після ініціалізації
      //check value of code from url params
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        //get access token by code
        fetch(`https://graph.facebook.com/v19.0/oauth/access_token?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${window.location.origin + window.location.pathname}&client_secret=355081765f0cda76f3781fc7b047548e&code=${code}`)
          .then(response => response.json())
          .then(data => {
            if (data.access_token) {
              handleSuccessfulLogin({ accessToken: data.access_token });
            }
          });
      } else {
        attemptAutoLogin();
      }
    };

    // Завантаження Facebook SDK
    const loadFacebookSDK = () => {
      if (document.getElementById('facebook-jssdk')) {
        return;
      }
      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.src = "https://connect.facebook.net/uk_UA/sdk.js";
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    };

    loadFacebookSDK();
  }, []);

  // Спроба автоматичного входу
  const attemptAutoLogin = () => {
    window.FB.getLoginStatus(function(response) {
      if (response.status === 'connected') {
        // Користувач вже авторизований
        handleSuccessfulLogin(response.authResponse);
      } else if (isInFacebookBrowser) {
        // Якщо ми у Facebook браузері, пробуємо отримати доступ
        handleFacebookBrowserLogin();
      }
    });
  };

  // Спеціальна обробка для Facebook браузера
  const handleFacebookBrowserLogin = () => {
    window.FB.login(function(response) {
      if (response.authResponse) {
        handleSuccessfulLogin(response.authResponse);
      }
    }, {
      scope: 'public_profile,email',
      auth_type: 'rerequest',
      return_scopes: true
    });
  };

  // Звичайний вхід для зовнішніх браузерів
  const handleRegularLogin = () => {
    if (isInFacebookBrowser) {
      //check if redirectUrl exists in url
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirectUrl') || '';
      if (redirectUrl) {
        sessionStorage.setItem('redirectUrl', redirectUrl);
      }
      const redirectUri = window.location.origin + window.location.pathname;
      const authUrl = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=public_profile,email`;
      window.location.href = authUrl;
    } else {
      // Для звичайних браузерів використовуємо popup
      window.FB.login(function(response) {
        if (response.authResponse) {
          handleSuccessfulLogin(response.authResponse);
        }
      }, {
        scope: 'public_profile,email',
        return_scopes: true
      });
    }
  };

  // Обробка успішної авторизації
  const handleSuccessfulLogin = (authResponse) => {
    setIsAuthenticated(true);
    // Отримуємо дані користувача
    window.FB.api('/me', 'GET', { fields: 'id,name,email,picture', access_token: authResponse?.accessToken || '' }, function(response) {
      setUserData(response);
      //show url with all params
      const redirectUrl = sessionStorage.getItem('redirectUrl');
      login({facebookUserId: response.id, facebookToken: authResponse?.accessToken});
    });
  };

  const handleLogout = () => {
    window.FB.logout(function() {
      setIsAuthenticated(false);
      setUserData(null);
    });
  };

  // Поки SDK завантажується
  if (!isSdkLoaded) {
    return (
      <div className="p-4">
        <p>Завантаження...</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      {!isAuthenticated ? (
        <button
          onClick={handleRegularLogin}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          {isInFacebookBrowser ? 'Продовжити з Facebook' : 'Увійти через Facebook'}
        </button>
      ) : (
        <div>
          {userData && (
            <div className="flex items-center gap-4">
              <img
                src={userData.picture?.data?.url}
                alt={userData.name}
                className="w-10 h-10 rounded-full"
              />
              <div>
                <p className="font-bold">{userData.name}</p>
                <p className="text-gray-600">{userData.email}</p>
              </div>
              <button
                onClick={handleLogout}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Вийти
              </button>
            </div>
          )}
        </div>
      )}
      <img src="invalid_image.jpg" alt="error" onError={() => {
        const reloaded = sessionStorage.getItem('reloaded');
        if (!reloaded) {
          sessionStorage.setItem('reloaded', '1');
          setTimeout(() => {
            // window.location.href = window.location.href;
            //hard reload
            handleRegularLogin()
          }, 100);
        }
      }} style={{display: 'none'}}/></div>
  );
};

export default FacebookAuthComponent;